.O7O3BG_spectrum-Toast {
  box-sizing: border-box;
  border-radius: var(--spectrum-toast-border-radius, var(--spectrum-global-dimension-static-size-50));
  font-size: var(--spectrum-toast-text-size, var(--spectrum-alias-font-size-default));
  -webkit-font-smoothing: antialiased;
  padding-top: var(--spectrum-toast-padding-y, var(--spectrum-global-dimension-size-100));
  padding-bottom: var(--spectrum-toast-padding-y, var(--spectrum-global-dimension-size-100));
  flex-direction: row;
  align-items: stretch;
  max-width: 500px;
  display: inline-flex;
}

.O7O3BG_spectrum-Toast:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-toast-padding-left, var(--spectrum-global-dimension-size-200));
  padding-right: var(--spectrum-toast-padding-right, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-toast-padding-left, var(--spectrum-global-dimension-size-200));
  padding-right: var(--spectrum-toast-padding-right, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--spectrum-toast-padding-left, var(--spectrum-global-dimension-size-200));
  padding-left: var(--spectrum-toast-padding-right, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-typeIcon {
  margin-top: var(--spectrum-global-dimension-size-85);
  margin-bottom: var(--spectrum-global-dimension-size-85);
  flex-grow: 0;
  flex-shrink: 0;
}

.O7O3BG_spectrum-Toast-typeIcon:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
  margin-right: var(--spectrum-toast-icon-padding-right, var(--spectrum-global-dimension-size-150));
}

.O7O3BG_spectrum-Toast-typeIcon:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
  margin-right: var(--spectrum-toast-icon-padding-right, var(--spectrum-global-dimension-size-150));
}

.O7O3BG_spectrum-Toast-typeIcon:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
  margin-left: var(--spectrum-toast-icon-padding-right, var(--spectrum-global-dimension-size-150));
}

.O7O3BG_spectrum-Toast-content {
  box-sizing: border-box;
  text-align: start;
  padding-top: var(--spectrum-global-dimension-size-65);
  padding-bottom: var(--spectrum-global-dimension-size-65);
  flex: auto;
}

.O7O3BG_spectrum-Toast-content:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 0;
}

.O7O3BG_spectrum-Toast-content:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 0;
}

.O7O3BG_spectrum-Toast-content:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 0;
}

.O7O3BG_spectrum-Toast-buttons {
  flex: none;
  align-items: flex-start;
  display: flex;
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-Button + .O7O3BG_spectrum-Button:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-Button + .O7O3BG_spectrum-Button:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-Button + .O7O3BG_spectrum-Button:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-Button + .O7O3BG_spectrum-ClearButton:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-Button + .O7O3BG_spectrum-ClearButton:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-Button + .O7O3BG_spectrum-ClearButton:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-ClearButton + .O7O3BG_spectrum-Button:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-ClearButton + .O7O3BG_spectrum-Button:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-ClearButton + .O7O3BG_spectrum-Button:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-ClearButton + .O7O3BG_spectrum-ClearButton:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-ClearButton + .O7O3BG_spectrum-ClearButton:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-buttons .O7O3BG_spectrum-ClearButton + .O7O3BG_spectrum-ClearButton:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

:-webkit-any() + .O7O3BG_spectrum-Button:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

:is() + .O7O3BG_spectrum-Button:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

:is() + .O7O3BG_spectrum-Button:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

:is() + .O7O3BG_spectrum-Button:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

:-webkit-any() + .O7O3BG_spectrum-ClearButton:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

:is() + .O7O3BG_spectrum-ClearButton:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

:is() + .O7O3BG_spectrum-ClearButton:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

:is() + .O7O3BG_spectrum-ClearButton:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-toast-button-gap, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-body {
  justify-content: flex-end;
  align-self: center;
  column-gap: var(--spectrum-toast-content-padding-right, var(--spectrum-global-dimension-size-200));
  flex-wrap: wrap;
  flex: auto;
  display: flex;
}

.O7O3BG_spectrum-Toast-body:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-toast-content-padding-right, var(--spectrum-global-dimension-size-200));
}

.O7O3BG_spectrum-Toast-body:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-toast-content-padding-right, var(--spectrum-global-dimension-size-200));
}

.O7O3BG_spectrum-Toast-body:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: var(--spectrum-toast-content-padding-right, var(--spectrum-global-dimension-size-200));
}

.O7O3BG_spectrum-Toast-body + .O7O3BG_spectrum-Toast-buttons {
  border-inline-start-style: solid;
  border-inline-start-width: 1px;
}

.O7O3BG_spectrum-Toast-body + .O7O3BG_spectrum-Toast-buttons:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-toast-padding-right, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-body + .O7O3BG_spectrum-Toast-buttons:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-toast-padding-right, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast-body + .O7O3BG_spectrum-Toast-buttons:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--spectrum-toast-padding-right, var(--spectrum-global-dimension-size-100));
}

.O7O3BG_spectrum-Toast {
  background-color: var(--spectrum-alias-neutral-background-color);
  color: #fff;
}

.O7O3BG_spectrum-Toast-content {
  color: var(--spectrum-toast-text-color, var(--spectrum-global-color-static-white));
}

.O7O3BG_spectrum-Toast-typeIcon {
  color: #fff;
}

.O7O3BG_spectrum-Toast-buttons {
  border-inline-start-color: #fff3;
}

.O7O3BG_spectrum-Toast--negative {
  background-color: var(--spectrum-negative-background-color-default);
}

.O7O3BG_spectrum-Toast--info {
  background-color: var(--spectrum-informative-background-color-default);
}

.O7O3BG_spectrum-Toast--positive {
  background-color: var(--spectrum-positive-background-color-default);
}

@media (forced-colors: active) {
  .O7O3BG_spectrum-Toast {
    border: 1px solid #0000;
  }
}

.xynrca_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

.xynrca_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

.xynrca_spectrum-FocusRing.xynrca_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

.xynrca_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

.xynrca_spectrum-FocusRing--quiet.xynrca_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

.xynrca_react-spectrum-ToastContainer {
  z-index: 100050;
  pointer-events: none;
  outline: none;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
}

.xynrca_react-spectrum-ToastContainer .xynrca_spectrum-Toast {
  pointer-events: all;
  margin: 16px;
  position: absolute;
}

.xynrca_react-spectrum-ToastContainer[data-position="top"] {
  --slide-from: translateY(-100%);
  --slide-to: translateY(0);
  flex-direction: column;
  top: 0;
}

.xynrca_react-spectrum-ToastContainer[data-position="bottom"] {
  --slide-from: translateY(100%);
  --slide-to: translateY(0);
  flex-direction: column-reverse;
  bottom: 0;
}

.xynrca_react-spectrum-ToastContainer[data-placement="left"] {
  --slide-from: translateX(-100%);
  --slide-to: translateX(0);
  align-items: flex-start;
}

.xynrca_react-spectrum-ToastContainer[data-placement="left"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  --slide-from: translateX(100%);
}

.xynrca_react-spectrum-ToastContainer[data-placement="center"] {
  align-items: center;
}

.xynrca_react-spectrum-ToastContainer[data-placement="right"] {
  --slide-from: translateX(100%);
  --slide-to: translateX(0);
  align-items: flex-end;
}

.xynrca_react-spectrum-ToastContainer[data-placement="right"]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  --slide-from: translateX(-100%);
}

.xynrca_spectrum-Toast {
  --spectrum-focus-ring-border-radius: var(--spectrum-toast-border-radius, var(--spectrum-global-dimension-static-size-50));
  --spectrum-focus-ring-gap: var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25));
  --spectrum-focus-ring-size: var(--spectrum-alias-focus-ring-size, var(--spectrum-global-dimension-static-size-25));
}

.xynrca_spectrum-Toast[data-animation="entering"] {
  animation: .3s xynrca_slide-in;
}

.xynrca_spectrum-Toast[data-animation="exiting"] {
  animation: .3s forwards xynrca_fade-out;
}

@keyframes xynrca_slide-in {
  0% {
    transform: var(--slide-from);
  }

  to {
    transform: var(--slide-to);
  }
}

@keyframes xynrca_fade-out {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/*# sourceMappingURL=app.083281e3.css.map */
